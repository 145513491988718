import logo from "./logo.svg";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/homepage";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import PrivacyPolicy from "./pages/privacypolicy";
import TermsConditions from "./pages/terms&conditions";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Homepage />} />
        <Route
            path={"/privacy-policy"}
            element={<PrivacyPolicy />}
          />
          <Route
            path={"/terms-and-conditions"}
            element={<TermsConditions/>}
          />
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;
