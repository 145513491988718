import React, { useEffect, useState } from "react";
import { Link as ScrollLink, Element } from "react-scroll";
import logo from "../assests/images/SVGLogo 1.png";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import apple from "../assests/images/app-ico 1.png";
import google from "../assests/images/g-play 1.png";
import {
  FaFacebook,
  FaInstagram,
  FaLocationDot,
  FaMessage,
} from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import ContactUsPage from "./contact";
import { getGulfHomepageService } from "../services/homepageServices";
import Footer from "../Common/Footer/footer";

const appleStoreLink = "https://www.apple.com"; // Replace with actual Apple Store link
const googlePlayLink = "https://play.google.com";

export default function TermsConditions() {
  const [contents, setContents] = useState(null); // State to manage CMS contents

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await getGulfHomepageService();
        console.log("API Response:", response); // Logging the entire response object

        if (response.status) {
          setContents(response.data[0]);
          // Set any flags or additional state as needed
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchContents(); // Invoke the fetch function
  }, []);

  return (
    <>
      {/* Header */}
      <header>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto d-flex justify-content-between align-items-center header-menu">
                <ScrollLink
                  to="contact"
                  smooth={true}
                  duration={500}
                  className="scroll-link"
                  activeClass="active"
                  spy={true}
                  offset={-70}
                >
                  Contact
                </ScrollLink>
                <ScrollLink to="contact">
                  <Button className="download-btn">Download App</Button>
                </ScrollLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      {/* Terms and Conditions Section */}
      <section className="terms-conditions-section">
        <Container>
          <h2 className="text-center"> Terms And Conditions</h2>
          <div className="terms-conditions-content">
            {contents && contents.gulfweb_terms_and_conditions ? (
              <div dangerouslySetInnerHTML={{ __html: contents.gulfweb_terms_and_conditions }} />
            ) : (
              <p>Loading Terms and Conditions...</p>
            )}
          </div>
        </Container>
      </section>

      <Element name="contact" className="element">
        <Footer />
      </Element>
  
    </>
  );
}
