import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import handMobile from "../assests/images/Group 1.png";
import { getGulfHomepageService } from "../services/homepageServices";
import { htmlToText } from "html-to-text";
import howImg from "../assests/images/how_it_works.png";
import profileOne from "../assests/images/profile.svg";
import skillsIcon from "../assests/images/skills.svg";
import verifiedTwo from "../assests/images/verifiedicon.svg";
import profileFull from "../assests/images/profilefull.svg";

function JoinUS() {
  const [contents, setContents] = useState(null); // State to manage CMS contents

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await getGulfHomepageService();
        console.log("API Response:", response); // Logging the entire response object

        if (response.status) {
          setContents(response.data[0]);
          // Set any flags or additional state as needed
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchContents(); // Invoke the fetch function
  }, []);

  const handleJoinClick = () => {
    // Replace these URLs with your actual store URLs
    const playStoreURL = "https://play.google.com/store/apps/details?id=com.gulfkaamapp&hl=en";
    const appStoreURL = "https://apps.apple.com";

    // Open both URLs in new tabs/windows
    window.open(playStoreURL, "_blank");
    window.open(appStoreURL, "_blank");
  };

  return (
    <>
      {/* join us section */}
      <section className="joinus-banner">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-5">
              {/* animation aos library */}
              <div
                className="joinus-padding"
                data-aos="fade-right"
                data-aos-delay="1000"
                data-aos-duration="1000"
              >
                <div className="joinus-content">
                  <h3 className="w-100 mt-3">
                    <span>{contents && contents.intro_title}</span>
                  </h3>
                  <p>
                    {contents && htmlToText(contents.intro_content)}
                    {/* Convert HTML to plain text */}
                  </p>
                  <Button className="download-btn" onClick={handleJoinClick}>
                    Join Today
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-hand-background">
                <img
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-offset="200px"
                  className="img-fluid img-hand"
                  src={handMobile}
                  alt="Hand Mobile"
                />
              </div>
            </div>
            <div className="col-lg-3">
              {/* animation aos library */}
              <div
                data-aos="fade-left"
                data-aos-delay="1000"
                data-aos-duration="1000"
              >
                <div className="mt-5 joinus-padding">
                  <div className="joinus-content-downloaded-content">
                    <h3>{contents && contents.register_user_title}</h3>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          (contents && contents.register_user_content) || "",
                      }}
                    ></span>
                  </div>
                  <div
                    data-aos="fade-left"
                    data-aos-delay="1000"
                    data-aos-duration="3000"
                  >
                    <div className="joinus-content-downloaded-content mt-3">
                      <h3> {contents && contents.ai_hero_title}</h3>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: contents?.ai_hero_content || "",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works">
        <div className="container">
          <h2> {contents && contents.hiw_title}</h2>
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="steps">
                <div className="step">
                  <div className="leftsec">
                    <img
                      className="img-fluid1"
                      src={profileOne}
                      alt="Profile"
                    />
                  </div>
                  <div className="right-sec">
                    <p>{contents && contents.hiw_title_1}</p>
                    <span>
                      {contents && htmlToText(contents.hiw_content_1)}
                    </span>
                  </div>
                </div>

                <div className="step">
                  <div className="leftsec">
                    <img
                      className="img-fluid1"
                      src={verifiedTwo}
                      alt="Verified"
                    />
                  </div>
                  <div className="right-sec">
                    <p>{contents && contents.hiw_title_2}</p>
                    <span>
                      {contents && htmlToText(contents.hiw_content_2)}
                    </span>
                  </div>
                </div>

                <div className="step">
                  <div className="leftsec">
                    <img className="img-fluid1" src={skillsIcon} alt="Skills" />
                  </div>
                  <div className="right-sec">
                    <p>{contents && contents.hiw_title_3}</p>
                    <span>
                      {contents && htmlToText(contents.hiw_content_3)}
                    </span>
                  </div>
                </div>
                
                <div className="step">
                  <div className="leftsec">
                    <img
                      className="img-fluid1"
                      src={profileFull}
                      alt="Complete Profile"
                    />
                  </div>
                  <div className="right-sec">
                  <p>{contents && contents.hiw_title_4}</p>
                    <span>
                    {contents && htmlToText(contents.hiw_content_4)}

                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7 col-12">
              <div className="how-img">
                <img
                  data-aos="fade-left"
                  className="img-fluid1"
                  src={howImg}
                  alt="How It Works"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default JoinUS;
