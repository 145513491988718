import React, { useEffect, useState } from "react";

import apple from "../../assests/images/app-ico 1.png";
import google from "../../assests/images/g-play 1.png";
import {
  FaFacebook,
  FaInstagram,
  FaLocationDot,
  FaMessage,
  FaPhoneVolume,
} from "react-icons/fa6";
import { MdAttachEmail, MdEmail } from "react-icons/md";
import { getGulfHomepageService } from "../../services/homepageServices";
import ContactUsPage from "../../pages/contact";

function Footer() {
  const [contents, setContents] = useState(null);
  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await getGulfHomepageService();
        console.log("API Response:", response);

        if (response.status) {
          setContents(response.data[0]);
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchContents(); // Invoke the fetch function
  }, []);
  const appleStoreLink = "https://www.apple.com"; // Replace with actual Apple Store link
  const googlePlayLink = "https://play.google.com/store/apps/details?id=com.gulfkaamapp&hl=en";
  return (
    <div>
      <footer className="footer">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="footer-left-portion">
                <h4>Download Our Gulf Kaam APP</h4>
                <div className="d-flex mt-4 mb-5">
                  <a
                    href={appleStoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={apple} alt="Download on the App Store" />
                  </a>
                  <a
                    href={googlePlayLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ms-3"
                  >
                    <img src={google} alt="Get it on Google Play" />
                  </a>
                </div>
                <div className="d-flex address-detail mt-5">
                  <p>
                    <FaLocationDot size={20} />
                  </p>
                  <span>{contents && contents.address} </span>
                </div>

                <div className="d-flex address-detail">
                  <p>
                    <FaPhoneVolume size={10} />
                  </p>
                  <span>{contents && contents.phone}(India)</span>
                </div>
                <div className="d-flex address-detail">
                  <p>
                    <FaPhoneVolume size={10} />
                  </p>
                  <span>{contents && contents.phone_dubai}(Dubai)</span>{" "}
                </div>
                <div className="d-flex  gap-3 mt-5 social-icons">
                  <div className="d-flex align-items-center address-detail">
                    <a
                      href={`mailto:${contents?.email}`}
                      className="social-link"
                      title={contents?.email}
                    >
                      {/* <FaMessage size={20} /> */}
                      <MdAttachEmail  size={20}  />
                    </a>
                  </div>
                  <div className="d-flex align-items-center address-detail">
                    <a
                      href={contents?.facebook_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                      title="Facebook"
                    >
                      <FaFacebook size={20} />
                    </a>
                  </div>
                  <div className="d-flex align-items-center address-detail">
                    <a
                      href={contents?.instagram_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                      title="Instagram"
                    >
                      <FaInstagram size={20} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ContactUsPage />
          </div>
          <div className="copy_right_text">
            <p className="text-center">
              © 2024 Gulf Kaam | All rights reserved. |{" "}
              <a href="/privacy-policy" className="privacy-policy-link">
                Privacy Policy
              </a>{" "}
              |{" "}
              <a href="/terms-and-conditions" className="privacy-policy-link">
                Terms & Conditions
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
