import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FaCheck } from "react-icons/fa";
import "../../index.css";

export default function ThanksModal({ open, setOpen, content, continueBtnFun, btnText }) {
  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Body>
        <div className="delete-modal">
          <FaCheck size={100} />
          <div>
            <span>Thank You</span>
            <p className="mt-2 mb-4">{content}</p>
            <div className="mt-3">
              <Button
                onClick={() => {
                  continueBtnFun();
                  setOpen(false); 
                }}
                className="delete-modal-yes-btn"
              >
                {btnText}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
