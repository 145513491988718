import axios from "axios";
import { API_URL_ENDPOINT } from "../shared/apiUrl/apiEndPoints";

export const addContactUsService = (data) => {
    return axios
      .post(API_URL_ENDPOINT.addContactUs, data)
      .then((res) => res.data)
      .catch((err) => err);
  };

  // gulfkaam homepage 
export const getGulfHomepageService = () => {
  return axios
    .get(API_URL_ENDPOINT.getGulfHomepage)
    .then((res) => res.data)
    .catch((err) => err);
};