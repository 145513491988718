import React, { useEffect, useState } from "react";
import { Link as ScrollLink, Element } from "react-scroll";
import logo from "../assests/images/SVGLogo 1.png";
import { Button, Container, Nav, Navbar, Row } from "react-bootstrap";
import handMobile from "../assests/images/Group 1.png";
import howitwork from "../assests/images/how_it_works.png";
import icon1 from "../assests/images/experince.svg";
import icon2 from "../assests/images/verified.svg";
import icon3 from "../assests/images/complete_profile.svg";
import icon4 from "../assests/images/profile_gulf.svg";
import apple from "../assests/images/app-ico 1.png";
import google from "../assests/images/g-play 1.png";
import {
  FaFacebook,
  FaInstagram,
  FaLocationDot,
  FaMessage,
} from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import userFriendlyImg from "../assests/images/Group 320.png";
import userFriendlyImg1 from "../assests/images/Group 321.png";
import imgGroup1 from "../assests/images/Group 298.png";
import imgGroup2 from "../assests/images/Group 299.png";
import imgGroup3 from "../assests/images/Group 300.png";
import imgGroup4 from "../assests/images/Group 301.png";
import imgGroup5 from "../assests/images/Group 289.png";
import aiPhoneImg from "../assests/images/Group 297.png";
import aibot from "../assests/images/aibot.png";
import "./index.css";
import { MarqueSvg } from "../assests/svg";
import ContactUsPage from "./contact";
import JoinUS from "./joinUS";
import { getGulfHomepageService } from "../services/homepageServices";
import { htmlToText } from "html-to-text"; // Import the library
import Footer from "../Common/Footer/footer";

const appleStoreLink = "https://www.apple.com"; // Replace with actual Apple Store link
const googlePlayLink = "https://play.google.com";

export default function Homepage() {
  const [contents, setContents] = useState(null); // State to manage CMS contents

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await getGulfHomepageService();
        console.log("API Response:", response); // Logging the entire response object

        if (response.status) {
          setContents(response.data[0]);
          // Set any flags or additional state as needed
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchContents(); // Invoke the fetch function
  }, []);
  return (
    <>
      {/* Header */}
      <header>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="#home">
              <img src={logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto d-flex justify-content-between align-items-center header-menu">
                <ScrollLink
                  to="how-it-works"
                  // smooth={true}
                  duration={500}
                  className="scroll-link"
                  activeClass="active"
                  spy={true}
                  offset={-70}
                >
                  How it works
                </ScrollLink>
                <ScrollLink
                  to="features"
                  // smooth={true}
                  duration={500}
                  className="scroll-link"
                  activeClass="active"
                  spy={true}
                  offset={-70}
                >
                  Features
                </ScrollLink>

                <ScrollLink
                  to="contact"
                  // smooth={true}
                  duration={500}
                  className="scroll-link"
                  activeClass="active"
                  spy={true}
                  offset={-70}
                >
                  Contact
                </ScrollLink>
                <ScrollLink to="contact">
                  <Button className="download-btn">Download App</Button>
                </ScrollLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      {/* Join Us Section */}
      <JoinUS />

      {/* Features Section */}
      <Element name="features" className="element">
        <section className="user-friendly-interface">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 p-4">
                <div className="row">
                  <div className="col-6">
                    <img
                      className="img-fluid user-friendly-img"
                      src={userFriendlyImg}
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      className="img-fluid user-friendly-img-2"
                      src={userFriendlyImg1}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  className="user-friendly-content"
                >
                  <h3> {contents && contents.feature_heading}</h3>
                  <h4>
                    {contents && contents.language_section_title_1}
                    {/* User-Friendly Interface in Your{" "} */}
                    {/* <span>Regional Language</span> */}
                  </h4>
                  <p>
                    {contents && htmlToText(contents.language_section_content_1)}{" "}
                  </p>
                  {/* <br/> */}
                  <h4>
                    {contents && contents.language_section_title_2}
                  </h4>
                  <p>
                    {contents && htmlToText(contents.language_section_content_2)}{" "}
                  </p>
                  <h4>
                    {contents && contents.language_section_title_3}
                  </h4>
                  <p>
                    {contents && htmlToText(contents.language_section_content_3)}{" "}
                  </p>
                  <h4>
                    {contents && contents.language_section_title_4}
                  </h4>
                  <p>
                    {contents && htmlToText(contents.language_section_content_4)}{" "}
                  </p>
                  <h4>
                    {contents && contents.language_section_title_5}
                  </h4>
                  <p>
                    {contents && htmlToText(contents.language_section_content_5)}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* How it Works Section */}
      <Element name="" className="element">
        <section className="ai-chat-bot">
          <div className="d-flex img-group-ai">
            <div
              className="ai-chat-bot-img ai-group-img1"
              data-aos="fade-left"
              data-aos-delay="1300"
              data-aos-duration="1400"
            >
              <img src={imgGroup1} />
            </div>
            <div
              className="ai-chat-bot-img ai-group-img2"
              data-aos="fade-left"
              data-aos-delay="700"
              data-aos-duration="1000"
            >
              <img className="img-fluid" src={imgGroup2} />
            </div>
            <div
              className="ai-chat-bot-img position-relative ai-group-img5"
              data-aos="fade-up"
              data-aos-duration="100"
            >
              <img className="img-fluid" src={imgGroup5} />
            </div>
            <div
              className="ai-chat-bot-img ai-group-img3"
              data-aos="fade-right"
              data-aos-delay="700"
              data-aos-duration="1000"
            >
              <img className="img-fluid" src={imgGroup3} />
            </div>
            <div
              className="ai-chat-bot-img ai-group-img4"
              data-aos="fade-right"
              data-aos-delay="1300"
              data-aos-duration="1400"
            >
              <img className="img-fluid" src={imgGroup4} />
            </div>
          </div>
          {/* <div className="marque_text">
            <div className="marque_line position-relative">
              <marquee behavior="scroll" direction="" scrollamount="20">
                <MarqueSvg />
              </marquee>
            </div>
          </div> */}
          <div className="container">
            <div className="row align-items">
              <div className="col-lg-6">
                <div className="ai-bot-content-left-portion">
                  <h3>
                    <span className="whitespace-nowrap1 text-light">
                      {contents && contents.ai_sub_title}
                    </span>
                    <span 
                    // className="whitespace-nowrap text-light"
                    >
                      {" "}
                      <span>{contents && contents.ai_title}</span>
                      </span>
                  </h3>
                  <p>{contents && htmlToText(contents.ai_content)}</p>
                </div>
              </div>
              <div className="col-lg-6 text-center position-relative">
                <svg
                  viewBox="0 0 200 200"
                  className="position-absolute cirecle-transition"
                >
                  <circle className="circle1" cx="100" cy="100" r="5"></circle>
                  <circle className="circle2" cx="100" cy="100" r="5"></circle>
                  <circle className="circle3" cx="100" cy="100" r="5"></circle>
                  <circle className="circle3" cx="100" cy="100" r="5"></circle>
                  <circle className="circle3" cx="100" cy="100" r="5"></circle>
                </svg>
                <img
                  className="img-fluid ai-bot-mobile-img"
                  style={{ height: "608px" }}
                  src={aiPhoneImg}
                />
                <img className="ai-bot" src={aibot} />
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* Contact Section */}
      <Element name="contact" className="element">
        <Footer />
      </Element>
    </>
  );
}
